import createSingleton from "@peterbee/react-singleton";
import i18n from "./i18n/i18n";
import {createTheme} from "@mui/material";
import { dashboardAxiosInstance } from "./custom/components/axios/AxiosInstance";
import { DASHBOARD_BFF_API } from "./utils/api";
import {routesPaths} from "./routing/routesPaths";

async function importAll(r) {
    let resources = {};
    r.keys().forEach((key) => {
        resources[key.replace("./", "")] = r(key);
    });

    return resources;
}


const [appStaticData, updateAppStaticData] = createSingleton(() => {
    let staticData = {images: {}};

    const muiTheme = createTheme({
        palette: {
            primary: {
                main: '#0052cc',
            },
            secondary: {
                main: '#edf2ff',
            },
        },
    });

    // docs here: https://webpack.js.org/guides/dependency-management/#require-context
    importAll(require.context('./images/png', false, /\.png$/)).then((pngImages) => {
        staticData.images.png = pngImages;
    });
    importAll(require.context('./images/ico', false, /\.ico$/)).then((icoImages) => {
        staticData.images.ico = icoImages;
    });
    // this is to load a single image: require("./images/png/blahblah.png")

    const i18nInstance = i18n.initI18n();


    staticData.muiTheme = muiTheme;
    staticData.i18n = i18nInstance;


    return staticData;
});

export default appStaticData;
export {updateAppStaticData};
