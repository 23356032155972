import React, {useEffect, useState} from "react";
import moment from "moment";
import "moment/locale/it";
import "moment/locale/en-gb";
import PublishSubscribe from "publish-subscribe-js";
import {topic} from "../../../constants/constants";
import {ConfigProvider} from "antd";

/**
 * Provides a uniform configuration support for Antd components.
 * <p/>Mainly used for providing I18n to the components.
 *
 * @param props The properties of this component.
 * <p/>Same as for the Antd ConfigProvider component,
 * but you can't override the <i>locale</i> property.
 * <p/>
 * @see https://ant.design/components/config-provider/#API
 * @returns {JSX.Element}
 * @constructor
 */
const AntdConfigProvider = (props) => {
    const [locale, setLocale] = useState();

    const changeLocale = (theLocale) => {
        moment.updateLocale('it', {
            months: 'Gennaio_Febbraio_Marzo_Aprile_Maggio_Giugno_Luglio_Agosto_Settembre_Ottobre_Novembre_Dicembre'.split(
                '_'
            ),
            monthsShort: 'Gen_Feb_Mar_Apr_Mag_Giu_Lug_Ago_Set_Ott_Nov_Dic'.split('_'),
            weekdays: 'Domenica_Lunedì_Martedì_Mercoledì_Giovedì_Venerdì_Sabato'.split(
                '_'
            ),
            weekdaysShort: 'Dom_Lun_Mar_Mer_Gio_Ven_Sab'.split('_'),
            weekdaysMin: 'Do_Lu_Ma_Me_Gi_Ve_Sa'.split('_')
        });
        moment.locale(theLocale.locale); // for this to work, you also have to import locale files from moment/locale/ (see imports)
        setLocale(theLocale);
    }

    useEffect(() => {
        PublishSubscribe.subscribe(topic.i18n.languageChange, changeLocale);
    }, []);

    return(
        <ConfigProvider {...props} locale={locale}>
            {props.children}
        </ConfigProvider>
    );
}

export default AntdConfigProvider;