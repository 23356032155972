
import toyota_logo from "../images/svg/logo-toyota.svg";
import lexus_logo from "../images/LFS_logo_horizontal.jpg"
import {LEXUS_DOMAIN, LEXUS_USER_MANUAL, TOYOTA_USER_MANUAL} from "../utils/api"
var url = window.location.href;
var enabledModules = sessionStorage.getItem("enabledModules");


export const toyotaOrLexusElement = (elementName) => {
    if(url.includes(LEXUS_DOMAIN)){
        if(elementName.includes("btn-toyota")){
            return "btn-lexus"
        }
        elementName = elementName+"-lexus"
    }
    return elementName
}

export const logoHandler = () => {
    if (url.includes(LEXUS_DOMAIN)) {
        return lexus_logo
    } else {
        return toyota_logo
    }
}

export const colorSetter = () => {
    
    if (url.includes(LEXUS_DOMAIN)) {
        return "#13248C"
    } else {
        return "#FF0022"
    }
}

export const selectCssLexusId = (element) =>{
    if (url.includes(LEXUS_DOMAIN)) {
        switch(element){
            case "th":
                return "th-lexus"
            case "submenu":
                return "ul-lexus"
            case "colorFontSider":
                return "a-lexus"
            case "spin":
                return "spin-lexus"
        }
    }

}

export const getBrand = () =>{
    if (url.includes(LEXUS_DOMAIN)) {
        return "LEXUS"
    }
    return "TOYOTA"
}

export const isLexusUrl = () =>{
    if (url.includes(LEXUS_DOMAIN)) {
        return true
    }
    return false
}

export const brandSelected = () =>{

    if( enabledModules.includes("ADMIN"))
        return [{code:"ALL", label: "TUTTI"}, {code:"LEXUS", label: "LEXUS"}, {code:"TOYOTA", label: "TOYOTA"}]
  
    if(url.includes(LEXUS_DOMAIN)) {
        return [{code:"ALL", label: "TUTTI"}, {code:"LEXUS", label: "LEXUS"}]
    }
    return [{code:"TOYOTA", label: "TOYOTA"}, {code:"ALL", label: "TUTTI"}]
}

export const getUserManualName = () => {
    if (url.includes(LEXUS_DOMAIN)) {
        return LEXUS_USER_MANUAL;
    }
    return TOYOTA_USER_MANUAL;
}