import * as PageContainer from "../pages";
import {routesPaths} from "./routesPaths";


export function menuRoutes(appContextData) {
    const t = appContextData.i18n.t;

    let routes = [];

    if (t) {
        const users = {
            path: routesPaths.usersDashboard,
            name: t("titleCase.user", {count: 0}).toUpperCase(),
            component: PageContainer.UsersDashboard,
            routes: [
                {
                    path: routesPaths.usersDashboard,
                    name: t("titleCase.user", {count: 0}),
                    id: "cUsers",
                    component: PageContainer.UsersDashboard
                }
            ]
        };

        const transactions = {
            path: routesPaths.transactionsDashboard,
            name: t("titleCase.transaction", {count: 0}).toUpperCase(),
            component: PageContainer.TransactionsDashboard,
            routes: [
                {
                    path: routesPaths.transactionsDashboard,
                    name: t("titleCase.transaction", {count: 0}),
                    id: "cTransactions",
                    component: PageContainer.TransactionsDashboard
                },
                {
                    path: routesPaths.uploadODL,
                    name: "Carica ODL",
                    id: "uploadODL",
                    component: PageContainer.UploadODLContainer
                }
            ]
        };


        const performance = {
            path: routesPaths.performance,
            name: "PERFORMANCE",
            component: PageContainer.Performance,
            routes: [
                {
                    path: routesPaths.performance,
                    name: "Concessionarie",
                    id: "performanceConcessionarie",
                    component: PageContainer.Performance,
                }
            ]
        };

        const statistics = {
            path: routesPaths.usersStatistics,
            name: t("titleCase.statistics").toUpperCase(),
            component: PageContainer.UsersStatistics,
            routes: [
                {
                    path: routesPaths.usersStatistics,
                    name: t("titleCase.user", {count: 0}),
                    id: "cUsers",
                    component: PageContainer.UsersStatistics
                }
            ]
        };

        const ticket = {
            path: routesPaths.ticket,
            name: t("titleCase.ticket").toUpperCase(),
            component: PageContainer.Ticket,
            routes: [
                {
                    path: routesPaths.ticket,
                    name: t("titleCase.segnalazione"),
                    id: "cUsers",
                    component: PageContainer.Ticket
                }
            ]
        }

        const admin = {
            path: routesPaths.admin,
            name: "ADMIN",
            component: PageContainer.AdminDashboard,
            routes: [
                {
                    path: routesPaths.admin,
                    name: "Concessionarie",
                    id: "cUsers",
                    component: PageContainer.AdminDashboard
                },
                {
                    path: routesPaths.addFiliale,
                    name: "Aggiungi filiale",
                    id: "addFiliale",
                    component: PageContainer.FilialeCreate
                },
                {
                    path: routesPaths.uploadUserManual,
                    name: "Carica Manuale",
                    id: "uploadUserManual",
                    component: PageContainer.UploadUserManual
                },
                {
                    path: routesPaths.utenze,
                    name: "Utenze",
                    id: "viewUsers",
                    component: PageContainer.Utenze
                },
                {
                    path: routesPaths.addDealer,
                    name: "Aggiungi utenza",
                    id: "addDealer",
                    component: PageContainer.UsersCreate
                },
                {
                    path: routesPaths.viewPayment,
                    name: "Log Pagamenti",
                    id: "viewLog",
                    component: PageContainer.PaymentLog
                }
            ]
        };

        const routesOrder = [users.name, transactions.name, statistics.name, ticket.name, admin.name];

        if (appContextData.enabledModules.includes("USERS")) {
          routes.push(users);
        }
        if (appContextData.enabledModules.includes("TRANSACTIONS")) {
          routes.push(transactions);
        }
        routes.push(ticket);
        routes.push(statistics);
        if (appContextData.enabledModules.includes("ADMIN")) {
            routes.push(admin);
        }
        let newRoutes = routesOrder.map(el => routes.find(element => element.name === el) || {});
        routes = newRoutes.filter(value => Object.keys(value).length !== 0);
    }

    return routes;
}
