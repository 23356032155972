import {Route} from "react-router-dom";
import React from "react";

// wrap <Route> and use this everywhere instead, then when
// sub routes are added to any route it'll work
export const RouteWrapper = routeProps => (
    <Route
        path={routeProps.path}
        exact={routeProps.exact}
        render={props => {
            if (routeProps.component) {
                return (
                    // pass the sub-routes down to keep nesting
                    <routeProps.component {...props} />
                );
            }
        }}
    />
);