import React from "react";


const HomePage = (props) => {

  return (
    <div>
    </div>
  )
};

export default HomePage;